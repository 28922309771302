import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const LiveStreamingMusicProductionPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Live Streaming Music Production | Ask Me Anything | Jimmy Ether"
        ogDescription="I live stream regularly on TikTok, YouTube, Twitch, Facebook, Twitter and sometimes IG. Feel free to ask me questions or be a fly on the wall while I work."
        image="https://jimmyether.com/images/live-streaming-music-production.jpg"
        url="https://jimmyether.com/live-streaming-music-production/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Live Streaming Music Production</h1>
            <p>
              One of my frustrations with much of social media is that quick one minute videos and comment threads don't leave much room for context. So, I'm trying to do less short form videos about complex topics like recording, mixing, and mastering.
            </p>
            <p>
              Instead, I'm regularly live streaming my sessions so you can get a peak into my process and approach. The schedule changes based on the social network, but if you follow and subscribe on your platform of choice, you should get notifications when I go live.
            </p>
            <p>
              Don't hesitate to ask any questions you may have. Or if there is something you would like me to demonstrate, feel free to ask. Also feel free to use the form at the bottom of the page if you have a topic request.
            </p>
            <p>
              Below are my primary platforms with information on how to find my live streams. I'll try to keep them updated with estimated times when possible.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/live-streaming-music-production.jpg" alt="Live Streaming Music Production" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/youtube-subscribe-to-live.jpg" alt="Live Streaming Music Production on YouTube" />
          </div>
        }
        textRight={
          <div>
            <h2>YouTube</h2>
            <p>First, <a href="https://www.youtube.com/channel/UC_wJrTHrWsYY3VvNxYMkBfg" target="_blank">subscribe to my YouTube channel</a>. Then click on the bell icon, and select the "All" option. You'll be notified anytime I go live.</p>
            <p>I usually live stream on YouTube Tuesdays and Thursdays from 4:30pm to 6:00pm Eastern time. I'm likely to pop on Saturday or Sunday depending on what's going on, but times may vary. </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>TikTok</h2>
            <p>First, <a href="https://www.tiktok.com/@jimmyether" target="_blank">follow me on TikTok</a>. Then click on the bell icon, and select the "All" option. You'll be notified anytime I go live.</p>
            <p>I usually live stream on TikTok Mondays, Wednesdays and Fridays from 4:30pm to 6:00pm Eastern time. I'm likely to pop on Saturday or Sunday depending on what's going on, but times may vary. </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/tiktok-subscribe-to-live.jpg" alt="Live Streaming Music Production on TikTok" />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/twitch-subscribe-to-live.jpg" alt="Live Streaming Music Production on Twitch" />
          </div>
        }
        textRight={
          <div>
            <h2>Twitch</h2>
            <p>First, <a href="https://www.twitch.tv/jimmyether" target="_blank">follow to my Twitch channel</a>. Then click on the bell icon. You'll be notified anytime I go live.</p>
            <p>I usually live stream on Twitch Tuesdays and Thursdays from 4:30pm to 6:00pm Eastern time. I'm likely to pop on Saturday or Sunday depending on what's going on, but times may vary. </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Facebook</h2>
            <p>First, <a href="https://www.facebook.com/ear4audio" target="_blank">like my page on Facebook</a>. Then click on the three dots icon, click "Follow Settings", click "Video", and select the "All Notifications" option. Be sure to click "Update" wnen you are done. You'll be notified anytime I go live.</p>
            <p>I usually live stream on Facebook Tuesdays and Thursdays from 4:30pm to 6:00pm Eastern time. I'm likely to pop on Saturday or Sunday depending on what's going on, but times may vary.</p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/facebook-subscribe-to-live.jpg" alt="Live Streaming Music Production on Facebook" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Twitter</h2>
            <p><a href="https://twitter.com/jimmyether" target="_blank">Follow me on Twitter</a>. The live streams just show up as new tweets, so you just have to look for it I guess.</p>
            <p>I usually live stream on Twitter Tuesdays and Thursdays from 4:30pm to 6:00pm Eastern time. I'm likely to pop on Saturday or Sunday depending on what's going on, but times may vary.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Instagram</h2>
            <p><a href="https://www.instagram.com/jimmyether/" target="_blank">Follow me on Instagram</a>. It's pretty rare and random that I'll pop on live. IG makes it difficult to stream. But I do it from time to time.</p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default LiveStreamingMusicProductionPage
