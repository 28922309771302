import React from "react"
import { Button } from "semantic-ui-react"
import scrollTo from "gatsby-plugin-smoothscroll"

export default function ScrollToButton({ content, id, icon, style }) {
  function scrollToEl() {
    const el = document.getElementById(id)
    if (!el) return

    scrollTo(`#${id}`)
  }

  return (
    <Button
      onClick={scrollToEl}
      style={style}
      content={content}
      size="tiny"
      icon={icon}
    />
  )
}

ScrollToButton.defaultProps = {
  id: null,
  icon: "play circle",
  content: "Click to see",
  style: {},
}
