import React, { } from "react"
import { Button } from "semantic-ui-react"

export default function QuickLinkButton({ url, content, icon }) {
  return (
    <Button
      as="a"
      href={url}
      target="_blank"
      className=""
      content={content}
      size="tiny"
      icon={icon}
    />
  )
}
